const OK_STATUSES = ["success", "login-success", "login-otp"];
import * as baseAdapter from '../adapters/baseAdapter';
export const API_URL = process.env.REACT_APP_API_URL;// === "production" ? process.env.REACT_APP_API_URL : "";
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;// || 'http://127.0.0.1:5000';

/** Parses server response and throws an error if a non-ok status is received */
export function parseResponse(res, okStatuses=OK_STATUSES) {
	var data = res.data;

	// if(!isSuccessResponse(res, okStatuses)) {
	// 	throw new Error(data);
	// }

	return (data.data == null) ? '' : data.data.response;
}

export function isSuccessResponse(res, okStatuses=OK_STATUSES) {
	var data = res.data;
	return okStatuses.includes(data.status);
}

export function appRoute(id) {
	var route = `${API_URL}/api/applications/`;

	if(id != null) {
		route += id;
	}

	return route;
}

export function handleError(err, StatusManager, statusManagerErrString=null) {
	try {
		var errMessage = `Error: ${err.toString()}`;
		if(statusManagerErrString) {
			StatusManager.setStatus(statusManagerErrString);
			errMessage += `, Status Manager: ${statusManagerErrString}`
		} else {
			StatusManager.setStatus(err.toString());
		}

		if(err.request) errMessage += `, ResponseURL: ${err.request.responseURL}`;
		if(err.stack) errMessage += `, Stack: ${err.stack}`;
	} catch(handleErr) {
		console.error(handleErr);
	}
}
